<template>
  <div>
    <!-- Title and subtitle -->
    <h1 class="auth-title login-title">{{ $t('LOGIN.TITLE') }}</h1>

    <h6 class="auth-title login-title" style="font-size: 28px;">{{ (userTypeString) }}</h6>

    <p v-if="!authError" class="auth-subtitle">{{ $t('LOGIN.SUBTITLE') }}</p>
    <p v-else-if="!unverifiedEmail" class="text-error auth-error login-error">{{ authError }}</p>
    <div v-else class="text-error auth-error login-error">
      <p class="inline">{{ unverifiedEmailText[0] }}</p>
      <p class="inline link" @click="resendVerificationEmail()"> {{ unverifiedEmailText[1] }} </p>
    </div>
    <p v-if="inviteStateError" class="text-error">
      Dit account is recent uitgenodigd door een andere gebruiker. Bekijk je e-mail om het uitnodigingsproces af te ronden.
    </p>
    <!-- Form -->
    <FormWrapper :send-form-data="validateData" class="page-form-wrapper">
      <template #form-content>

        <!-- Email -->
        <InputField :field-title="`${$t('EMAIL_ADDRESS')}`"
                    :field-name="'email'"
                    :rules="'required'"
                    :type="'email'"
                    :placeholder="$t('EMAIL_ADDRESS')"
                    :cy-selector="'name-input'"
                    :input-icon="require('../../../assets/icons/icn_email.svg')"
                    :input-icon-error="require('../../../assets/icons/icn_error.svg')"
                    :api-error-text="authError"
                    :display-api-errors="false"
                    :value="getInitialEmailValue()"
                    class="auth-form-element"/>

        <!-- Password -->
        <InputField :field-title="`${$t('PASSWORD')}`"
                    :field-name="'password'"
                    :rules="'required'"
                    :type="'password'"
                    :placeholder="$t('PASSWORD')"
                    :cy-selector="'name-input'"
                    :input-icon="require('../../../assets/icons/icn_password.svg')"
                    :input-icon-error="require('../../../assets/icons/icn_error.svg')"
                    :api-error-text="authError"
                    :display-api-errors="false"
                    class="auth-form-element"/>


        <!-- Password -->
        <InputField v-if="needTwoFA"
                    :field-title="`${$t('ONE_TIME_PASSCODE')}`"
                    :field-name="'passcode'"
                    :rules="'required'"
                    :type="'number'"
                    :placeholder="'123456'"
                    :max-count="6"
                    :cy-selector="'name-input'"
                    :input-icon="require('../../../assets/icons/icn_password.svg')"
                    :input-icon-error="require('../../../assets/icons/icn_error.svg')"
                    :api-error-text="authError"
                    :display-api-errors="false"
                    class="auth-form-element"/>

        <div class="login-options">
          <!-- Remember me option -->
          <Checkbox :field-title="''"
                    :text="$t('LOGIN.REMEMBER')"
                    :field-name="'rememberMe'"
                    :rules="''"
                    class="remember-me"
                    checkmark-color="var(--blue_dark)"/>

          <!-- Remember me option -->
          <router-link v-if="userTypeScope === 'teacher'" :to="{name: ROUTE_NAMES_AUTH.FORGOT_PASSWORD}" class="forgot-password">{{ $t('FORGOT.TITLE') }}
          </router-link>
        </div>
      </template>

      <template #button-submit>
        <button type="submit" class="pink-button button-login">
          {{ $t('LOG_IN') }}
        </button>
      </template>

    </FormWrapper>

    <!-- TODO: Add 2.1.6 - Success toast. -->

    <!-- Register prompt -->
    <AuthPrompt
      v-if="userTypeScope === 'teacher'"
      :text="$t('REGISTER_PROMPT.LINK')"
      :route-name="ROUTE_NAMES_AUTH.REGISTER_AS"
      :link-text="$t('REGISTER_PROMPT.TEXT')"
      class="auth-prompt"/>
  </div>
</template>

<script>
import FormWrapper from '@/components/yo-form/FormWrapper'
import InputField from '@/components/yo-form/InputField'
import Checkbox from '@/components/yo-form/Checkbox'
import { ROUTE_NAMES_AUTH } from '@/router/modules/auth'
import { ROUTE_NAMES_PROFESSIONAL } from '@/router/modules/professional'
import AuthPrompt from '@/components/elements/auth/AuthPrompt'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import LocalDataHandler from '@/utils/handlers/LocalDataHandler'
import { USER_TYPES } from '@/models/User'
import { ROUTE_NAMES_TEACHER_PORTAL } from '@/router/modules/teacherPortal'
import { SEND_TEACHER_LOGIN, RESEND_VERIFICATION_EMAIL, REMOVE_AUTH_ERROR } from '@/store/modules/auth/actions'
import { computed, ref } from '@vue/reactivity'
import { ROUTE_NAMES_HELPDESK } from '@/router/modules/helpdesk'
import { ROUTE_NAMES_CMS } from '@/router/modules/cms'
import nl from '@/utils/language/nl.json'
// import ApiHandler from '../../../utils/handlers/ApiHandler'

export default {
  name: 'Login',
  components: {
    AuthPrompt,
    Checkbox,
    FormWrapper,
    InputField,
  },
  setup() {
    const store = useStore()
    const router = useRouter()
    const route = useRoute()
    const userType = route.params.userType

    store.dispatch(REMOVE_AUTH_ERROR)

    let userTypeString = 'Leraren portaal'
    let userTypeScope = USER_TYPES.TEACHER
    if (userType) {
      if (userType === USER_TYPES.HELPDESK) {
        userTypeString = 'Helpdesk'
        userTypeScope = USER_TYPES.HELPDESK
      }
      else if (userType === USER_TYPES.CMS) {
        userTypeString = 'CMS'
        userTypeScope = USER_TYPES.CMS
      }
    }

    const isProduction = computed(() => process.env.VUE_APP_IS_PRODUCTION === 'production')

    const needTwoFA = computed(() => (userType === USER_TYPES.CMS || userType === USER_TYPES.HELPDESK) && isProduction.value)
    const unverifiedEmail = ref('')
    const inviteStateError = ref(false)

    function validateData(data) {
      unverifiedEmail.value = ''
      login(data)
        .then((response) => {
          if (response) {
            if (response.error === 'user_is_in_invite_state') {
              inviteStateError.value = true
              return false
            }
            if (response.error === 'invalid_credentials' || response.error === '2fa_code_invalid') {
              return false
            }
            if (response.error === 'old_account_requires_new_password') {
              router.push({ name: ROUTE_NAMES_AUTH.EXPIRED_PASSWORD, params: { email: data.email } })
              return false
            }
            if (response.error === 'email_not_verified') {
              unverifiedEmail.value = data.email
              return false
            }
            inviteStateError.value = false
            redirectByUserType()
          }
        })
        .catch((error) => {
          // console.error(error)
        })
    }

    function redirectByUserType() {
      const loggedInUser = LocalDataHandler.getUser()
      if (!loggedInUser) return
      const userType = loggedInUser.type
      const isApproved = loggedInUser.isApproved

      if (userType === USER_TYPES.TEACHER) {
        // approved teacher navigate to class page, while unapproved to 'waiting room' page
        if (isApproved) {
          router.push({ name: ROUTE_NAMES_TEACHER_PORTAL.MY_CLASSES })
        } else {
          router.push({ name: ROUTE_NAMES_TEACHER_PORTAL.WAITING_ROOM })
        }
      }
      else if (userType === USER_TYPES.PROFESSIONAL) {
        router.push({ name: ROUTE_NAMES_PROFESSIONAL.INSTRUCTIONS })
      }
      else if (userType === USER_TYPES.HELPDESK) {
        router.push({ name: ROUTE_NAMES_HELPDESK.SCHOOL_OVERVIEW })
      }
      else if (userType === USER_TYPES.CMS) {
        router.push({ name: ROUTE_NAMES_CMS.INSTRUCTIONS })
      }
    }

    function login(data) {
      data.userTypeScope = userTypeScope
      return store.dispatch(SEND_TEACHER_LOGIN, data)
    }

    function getInitialEmailValue() {
      const email = route.query.email
      if (email) {
        return email
      }

      return ''
    }

    function resendVerificationEmail() {
      store.dispatch(RESEND_VERIFICATION_EMAIL, unverifiedEmail.value).then(response => {
        router.push({ name: ROUTE_NAMES_AUTH.REGISTER_EMAIL_SENT, query: { email: unverifiedEmail.value } })
      })
    }

    const unverifiedEmailText = [
      'Dit e-mailadres is nog niet geverifieerd.',
      ' Stuur nieuwe e-mail.'
    ]

    /** API Error **/
    const authError = computed(() => store.getters.getAuthError)

    return {
      ROUTE_NAMES_AUTH,
      authError,
      userTypeString,
      userTypeScope,
      unverifiedEmail,
      unverifiedEmailText,
      needTwoFA,


      inviteStateError,

      // Form
      validateData,
      getInitialEmailValue,
      resendVerificationEmail,
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/base.variables";
@import "~@/assets/css/base.mixins";

.login-title {
  margin-bottom: rem(14);
}

.button-login {
  margin-top: rem(56);
}

.login-options {
  align-items: center;
  display: flex;
  justify-content: space-between;

  .remember-me {
    margin-bottom: 0;
  }

  .forgot-password {
    color: var(--blue_link);
    font-size: rem(16);
    font-weight: 500;
    letter-spacing: 0;
    line-height: rem(19);
  }
}



.button-login {
  width: rem(190);
}

.login-error {
  margin-bottom: rem(42);
  text-align: center;
}

.inline{
  display: inline;
}

.link{
  font-weight: 600;
  color: var(--blue_link);
  cursor: pointer;
}
</style>
